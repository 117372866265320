import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
    isAuthenticated: false,
    name: '',
    username: '',
    permissions: [],
    message: null,
    roles: ['not-logged-in'],
    org_name: null,
};

const authSlice = createSlice({
    name: 'authentication',
    initialState: initialAuthState,
    reducers: {
        login(state, action) {
            state.isAuthenticated = true;
            state.name = action.payload.name;
            state.username = action.payload.username;
            state.permissions = action.payload.permissions ? [...action.payload.permissions] : [...state.permissions];
            state.roles = action.payload.roles ? [...action.payload.roles] : [...state.roles];
            state.org_name = action.payload.org_name;
            state.message = null;
        },
        setPermissions(state, action) {
            // if (state.isAuthenticated) {
            state.permissions = [...action.payload.perms];
            // }
        },
        setRoles(state, action) {
            state.roles = [...action.payload.roles];
        },
        logout(state, action) {
            state.isAuthenticated = false;
            state.name = '';
            state.username = '';
            state.permissions = [];
            state.roles = ['not-logged-in'];
            state.org_name = null;

            if (action) {
                state.message = action.payload.message;
            }
        },
        clearMsg(state) {
            state.message = null;
        },
    },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
