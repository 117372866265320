import {
  createTheme,
  responsiveFontSizes,
  adaptV4Theme,
} from '@mui/material/styles';

import {green, amber} from '@mui/material/colors';

const theme = responsiveFontSizes(
  createTheme(
    adaptV4Theme({
      typography: {
        fontFamily: `"Avenir", sans-serif`,
        fontSize: 16,
        fontWeightBold: 'bolder',
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        // body1: {
        //     fontSize: '18px',
        // },
        // h1: {
        //     fontSize: '5rem',
        //     fontWeight: 'normal',
        // },
        // h2: {
        //     fontSize: '4rem',
        //     fontWeight: 'normal',
        // },
        // h3: {
        //     fontSize: '3rem',
        //     fontWeight: 'normal',
        // },
        // h4: {
        //     fontSize: '2rem',
        //     fontWeight: 'normal',
        // },
        // h5: {
        //     fontSize: '1.5rem',
        //     fontWeight: 'normal',
        // },
        // h6: {
        //     fontSize: '0.75rem',
        //     fontWeight: 'normal',
        // },
      },
      palette: {
        secondary: amber,
        primary: green,
      },
      //   default: {
      //     main: 'rgba(38,238,238,1)',
      //   },
      // },
      spacing: 6,
    })
  )
);
// paper #f2f1ef
// #dad8d2

export default theme;
