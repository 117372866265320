import { Box, Grid } from '@mui/material';

const Layout = ({ children }) => {
    return (
        <>
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    minHeight: '85vh',
                    bgcolor: 'background.paper',
                }}
                container
                direction={'column'}
                justifyContent={'flex-start'}
            >
                <Grid
                    xs={12}
                    item
                    container
                    sx={{
                        bgcolor: 'background.paper',
                        px: 2,
                        py: 3,
                    }}
                >
                    {children}
                </Grid>
            </Grid>
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>{children}</Box>
        </>
    );
};

export default Layout;
