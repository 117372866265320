export const SIDE_NAV = [
    {
        title: 'Login',
        pageUrl: '/auth/login',
        id: 'login-menu',
        icon: null,
        role: 'not-logged-in',
        perm: null,
        items: null,
    },
    {
        title: 'Dashboard',
        pageUrl: '/dashboard',
        id: 'dashboard-menu',
        icon: 'ViewList',
        role: 'login-user-role',
        perm: null,
        items: null,
    },
    {
        title: 'Accounts',
        pageUrl: '/accounts',
        id: 'account-menu',
        icon: 'AccountBalance',
        role: 'login-user-role',
        perm: null,
        items: null,
    },
    {
        title: 'Checks',
        pageUrl: '/checks',
        id: 'check-menu',
        icon: 'LocalAtm',
        role: 'login-user-role',
        perm: null,
        items: null,
    },
    {
        title: 'Credit Card',
        pageUrl: '/credits',
        id: 'credit-card-menu',
        icon: 'CreditCard',
        role: 'login-user-role',
        perm: null,
        items: null,
    },
    {
        title: 'Bills',
        pageUrl: '/bills',
        id: 'bill-menu',
        icon: 'ViewList',
        role: 'login-user-role',
        perm: null,
        items: null,
    },
    {
        title: 'Mileage',
        pageUrl: '/mileage',
        icon: 'ViewList',
        role: 'login-user-role',
        perm: 'mileage:index',
        id: 'mileage-index'
    },
    {
        title: 'Reports',
        pageUrl: '/reports',
        id: 'reports-menu',
        icon: null,
        role: 'login-user-role',
        perm: null,
        items: [
            {
                title: 'Reports',
                pageUrl: '/reports',
                role: 'login-user-role',
                perm: null,
                id: 'reports-menu-m1',
            },
            {
                title: 'Profit & Loss',
                pageUrl: '/reports/profit-loss',
                role: 'login-user-role',
                perm: 'report:profit-loss',
                op: 'and',
                id: 'reports-menu-m2',
            },
            {
                title: 'Spending Summary',
                pageUrl: '/reports/spending-summary',
                role: 'login-user-role',
                perm: 'report:spending-summary',
                op: 'and',
                id: 'reports-menu-m3',
            },
            {
                title: 'Spending Detail',
                pageUrl: '/reports/spending-detail',
                role: 'login-user-role',
                perm: 'report:spending-detail',
                op: 'and',
                id: 'reports-menu-m4',
            },
            {
                title: 'Transactions',
                pageUrl: '/reports/transactions',
                role: 'login-user-role',
                perm: 'report:transactions',
                op: 'and',
                id: 'reports-menu-m5',
            },
        ],
    },
];

export const SIDE_NAV2 = [
    {
        title: 'Admin',
        pageUrl: null,
        role: ['admin', 'appadmin', 'user-admin', 'role-admin'],
        perm: null,
        id: 'admin-menu',
        icon: null,
        items: [
            {
                title: 'Users',
                pageUrl: '/admin/users',
                role: 'user-admin',
                perm: 'rbacuser:user:index',
                op: 'and',
                id: 'm1',
            },
            {
                title: 'Roles',
                pageUrl: '/admin/roles',
                role: 'role-admin',
                perm: 'rbacuser:role:index',
                id: 'm2',
            },
            {
                title: 'Permissions',
                pageUrl: '/admin/permissions',
                role: 'role-admin',
                perm: 'rbacuser:permission:index',
                id: 'm3',
            },
            {
                title: 'Rules',
                pageUrl: '/admin/rules',
                role: 'role-admin',
                perm: 'rbacuser:rule:index',
                id: 'm4',
            },
            {
                title: 'System Profiles',
                pageUrl: '/admin/sys-profiles',
                role: 'admin',
                perm: 'sys-profile:index',
                id: 'm5',
            },
            {
                title: 'Organizations',
                pageUrl: '/admin/orgs',
                role: null,
                perm: 'org:index',
                id: 'm6',
            },
            {
                title: 'Account Types',
                pageUrl: '/admin/account-types',
                role: null,
                perm: 'account-type:index',
                id: 'm7',
            },
            {
                title: 'Budget Groups',
                pageUrl: '/admin/budget-groups',
                role: null,
                perm: 'budget-group:index',
                id: 'm8',
            },
            {
                title: 'Budget Line Types',
                pageUrl: '/admin/budget-line-types',
                role: null,
                perm: 'budget-line-type:index',
                id: 'm9',
            },
            {
                title: 'Payees',
                pageUrl: '/admin/payees',
                role: null,
                perm: 'payee:index',
                id: 'm10',
            },
            {
                title: 'Transaction Tags',
                pageUrl: '/admin/tran-tags',
                role: null,
                perm: 'tran-tag:index',
                id: 'm11',
            },
            {
                title: 'Cars',
                pageUrl: '/admin/cars',
                role: null,
                perm: 'car:index',
                id: 'm12',
            },
        ],
    },
];
