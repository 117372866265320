import loadable from '@loadable/component';
import {Navigate, Redirect, Route, Routes} from 'react-router-dom';
import {useSelector} from 'react-redux';

const Main = loadable(() => import('../../pages/Main'));
const AccountsMain = loadable(() => import('../../pages/main/accounts/AccountsMain'));
const BillsMain = loadable(() => import('../../pages/main/bills/BillsMain'));
const ChecksMain = loadable(() => import('../../pages/main/checks/ChecksMain'));
const CreditsMain = loadable(() => import('../../pages/main/credits/CreditsMain'));

const ReportsMain = loadable(() => import('../../pages/reports/ReportsMain'));
const RolesMain = loadable(() => import('../../pages/admin/roles/RolesMain'));
const PermissionsMain = loadable(() => import('../../pages/admin/permissions/PermissionsMain'));
const RulesMain = loadable(() => import('../../pages/admin/rules/RulesMain'));
const UsersMain = loadable(() => import('../../pages/admin/users/UsersMain'));

const PayeesMain = loadable(() => import('../../pages/admin/payees/PayeesMain'));
const CarsMain = loadable(() => import('../../pages/admin/cars/CarsMain'));
const OrgsMain = loadable(() => import('../../pages/admin/orgs/OrgsMain'));
const SysProfilesMain = loadable(() => import('../../pages/admin/sys-profiles/SysProfilesMain'));
const TranTagsMain = loadable(() => import('../../pages/admin/tran-tags/TranTagsMain'));
const AccountTypesMain = loadable(() => import('../../pages/admin/account-types/AccountTypesMain'));
const BudgetGroupsMain = loadable(() => import('../../pages/admin/budget-groups/BudgetGroupsMain'));
const BudgetLineTypesMain = loadable(() => import('../../pages/admin/budget-line-types/BudgetLineTypesMain'));

const Login = loadable(() => import('../../pages/auth/Login'));
const Profile = loadable(() => import('../../pages/auth/Profile'));
const NotFound = loadable(() => import('../../pages/NotFound'));
const ChangePassword = loadable(() => import('../../pages/auth/ChangePassword'));
const ForgotPassword = loadable(() => import('../../pages/auth/ForgotPassword'));
const ResetPassword = loadable(() => import('../../pages/auth/ResetPassword'));

const MileageMain = loadable(() => import('../../pages/main/mileage/MileageMain'));

const ProtectedRoute = ({isAuthenticated, children}) => {
    if (!isAuthenticated) {
        return <Navigate to="/auth/login" replace/>;
    }

    return children;
};

const AppRoutes = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    return (
        <Routes>
            <Route path="/auth/login" element={<Login/>}/>
            <Route path="/auth/forgot-password" element={<ForgotPassword/>}/>
            <Route path="/auth/reset-password/:key" element={<ResetPassword/>}/>
            <Route path="/" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Main /></ProtectedRoute>}/>
            <Route path="/auth/profile" element={<ProtectedRoute isAuthenticated={isAuthenticated}><Profile/></ProtectedRoute>}/>
            <Route path="/auth/change-password" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ChangePassword/></ProtectedRoute>}/>
            <Route path="/accounts/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><AccountsMain/></ProtectedRoute>}/>
            <Route path="/bills/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><BillsMain/></ProtectedRoute>}/>
            <Route path="/checks/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ChecksMain/></ProtectedRoute>}/>
            <Route path="/credits/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><CreditsMain/></ProtectedRoute>}/>
            <Route path="/reports/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><ReportsMain/></ProtectedRoute>}/>
            <Route path="/mileage/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><MileageMain/></ProtectedRoute>}/>
            <Route path="/admin/users/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><UsersMain/></ProtectedRoute>}/>
            <Route path="/admin/rules/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><RulesMain/></ProtectedRoute>}/>
            <Route path="/admin/roles/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><RolesMain/></ProtectedRoute>}/>
            <Route path="/admin/permissions/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><PermissionsMain/></ProtectedRoute>}/>
            <Route path="/admin/payees/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><PayeesMain/></ProtectedRoute>}/>
            <Route path="/admin/tran-tags/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><TranTagsMain/></ProtectedRoute>}/>
            <Route path="/admin/account-types/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><AccountTypesMain/></ProtectedRoute>}/>
            <Route path="/admin/budget-groups/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><BudgetGroupsMain/></ProtectedRoute>}/>
            <Route path="/admin/budget-line-types/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><BudgetLineTypesMain/></ProtectedRoute>}/>
            <Route path="/admin/cars/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><CarsMain/></ProtectedRoute>}/>
            <Route path="/admin/orgs/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><OrgsMain/></ProtectedRoute>}/>
            <Route path="/admin/sys-profiles/*" element={<ProtectedRoute isAuthenticated={isAuthenticated}><SysProfilesMain/></ProtectedRoute>}/>
            <Route element={<NotFound/>}/>
        </Routes>
    );
};

export default AppRoutes;
