// import dotenv from 'dotenv';

// dotenv.config();

export const baseUrl = window.REACT_APP_API_URL ? window.REACT_APP_API_URL : process.env.REACT_APP_API_URL;

export const urls = {
    account: '/accounts',
    accountAssetInfo: '/account-asset-infos',
    accountBankInfo: '/account-bank-infos',
    accountCcInfo: '/account-cc-infos',
    accountLoanInfo: '/account-loan-infos',
    check: '/checks',
    credit: '/credits',
    bill: '/bills',
    transaction: '/transactions',
    budgetLine: '/budget-lines',
    budgetGroup: '/budget-groups',
    budgetLineType: '/budget-line-types',
    car: '/cars',
    payee: '/payees',
    org: '/orgs',
    accountType: '/account-types',
    tranTag: '/tran-tags',
    mileage: '/mileage',
    role: '/rbacuser/roles',
    permission: '/rbacuser/permissions',
    rule: '/rbacuser/rules',
    user: '/rbacuser/users',
    assignment: '/rbacuser/assignments',
    auth: '/rbacuser/auth',
    sysProfile: '/sysprofile/sys-profiles',
    report: '/reports',
};

export const baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export const drawerWidth = 240;

const curDate = new Date();

export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const TITLE = 'Home Finance Budgeting & Tracking';
export const COPYRIGHT = 'Pella Hosting & Website Solutions';
export const COPYRIGHT_DATES = `2024 - ${curDate.getFullYear()}`;
export const COPYRIGHT_LINK = 'https://pellahosting.com';
